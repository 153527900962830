// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

$fa-font-path: "../webfonts";
@import "../../../fontawesome-pro-5.10.2-web/scss/fontawesome.scss";
@import "../../../fontawesome-pro-5.10.2-web/scss/solid.scss";
@import "../../../fontawesome-pro-5.10.2-web/scss/regular.scss";
@import "../../../fontawesome-pro-5.10.2-web/scss/brands.scss";
@import "../../../fontawesome-pro-5.10.2-web/scss/light.scss";
@import "../../../fontawesome-pro-5.10.2-web/scss/duotone.scss";
@import "carte.scss";

$rose: rgba(208, 0, 123, 1);
$roseclair: rgba(208, 0, 123, .5);
$rosehover: rgba(208, 0, 123, .8);

$bleu: rgba(0, 58, 121, 1);
$bleuclair: rgba(106, 174, 220, 1);
$gris: rgb(153, 155, 156);
$grisclair: rgba(238, 242, 249, 1);
$boldtitreaccueil: 900;
$boldtitre: 500;
$borderradius: 30px;
$hauteurFleches: 250px;
body {
  background-color: $white;
  margin: 0;

}
.step-circle {
  &:hover {
    color: white;
  }
  line-height: $step-circle-diameter;

}

.step-circle-nohover {
  background: #2d91ff;

  &:hover {
    cursor: pointer;

  }
}
h4, .h4 {
  line-height: 1.875rem;
  margin-bottom: 0.75rem;
}
.res-circle {
  width: 17%;
  border-radius: 50%;
  line-height: 0;
  background: $grisclair;
  /* NECESSARY FOR TEXT */
  position: relative;
}
.res-circle:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.circle-txt {
  position: absolute;
  bottom: 41%;
  width: 100%;
  text-align: center;

}
$navbar-light-color:                rgba($bleufonce, .7);
$navbar-light-hover-color:          rgba($bleufonce, 9);
$navbar-light-active-color:         rgba($bleufonce, 1);
$navbar-light-disabled-color:       rgba($bleufonce, .3);
$navbar-light-toggler-border-color: rgba($bleufonce, .3);
.navbar-light {
  .navbar-brand {
    color: $navbar-light-brand-color;

    @include hover-focus {
      color: $navbar-light-brand-hover-color;
    }
  }

  .navbar-nav {
    .nav-link {
      color: $navbar-light-color;

      @include hover-focus {
        color: $navbar-light-hover-color;
      }

      &.disabled {
        color: $navbar-light-disabled-color;
      }
    }

    .show > .nav-link,
    .active > .nav-link,
    .nav-link.show,
    .nav-link.active {
      color: $navbar-light-active-color;
    }
  }

  .navbar-toggler {
    color: $navbar-light-color;
    border-color: $navbar-light-toggler-border-color;
  }

  .navbar-toggler-icon {
    background-image: $navbar-light-toggler-icon-bg;
  }

  .navbar-text {
    color: $navbar-light-color;
    a {
      color: $navbar-light-active-color;

      @include hover-focus {
        color: $navbar-light-active-color;
      }
    }
  }
}
.btn-rose {
  color: white;
  background-color: $rose;
  border-color: $roseclair;

  &:hover {
    color: white;
    background-color: $rosehover;
    border-color: $roseclair;

  }
}
.portrait, .text-small-device {
  display: none;
}


.navbar {
  padding: 0;
}

.footer-margin {
  height: 100px;
}

.nav-accueil {
  margin-bottom: 18rem;
}

.previous {
  position: fixed;
  width: 120px;
  height: auto;
  //right: 17px;
  left: auto;
  top: $hauteurFleches;
  margin-left: -3px;

  :focus {
    outline: 2px solid blue;
  }
}

.next {
  position: fixed;
  width: 120px;
  height: auto;
  //right: 17px;
  left: auto;
  top: $hauteurFleches;
  margin-left: -5px;

  :focus {
    outline: 2px solid blue;
  }

}

.rounded-card-pe {
  border-radius: 2rem;
}


.font-smaller {
  font-size: 100%
}

.fond-demission {
  background-color: #e2ff19;
}

/*.tippy-tooltip.sycorax-theme {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 8, 16, .15);
  color: $rose;
  box-shadow: 0 4px 14px -2px rgba(0, 8, 16, .08);
  border-radius: $borderradius;

}*/
.tippy-box[data-theme~='sycorax'] {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 8, 16, .15);
  color: $rose;
  box-shadow: 0 4px 14px -2px rgba(0, 8, 16, .08);
  border-radius: $borderradius;
}
.bordure-mot {
  border-style: solid;
  border-width: thin;
  padding-right: 3px;
  padding-left: 3px;
  //padding-bottom: 8px;
  //padding-top: 8px;
  margin-bottom: 3px;
  margin-top: 3px;
  border-color: $rose;
  border-radius: 30px;;

}

.titre-gros {
  font-size: $h3-font-size;
  color: $rose;
  font-variant: small-caps;
  line-height: 1;
}

.titre-popup-principale {
  font-size: $font-size-lg;
  line-height: 1;
  //font-variant: small-caps;
  color: $rose;
  text-align: center;
  margin-bottom: 15px;
  font-weight: bold;

}

.titre-popup-secondaire {
  font-size: $font-size-lg;
  line-height: 1;
  font-weight: bold;
  color: $rose;
  margin-top: 15px;
  margin-bottom: 8px;
}

.titre-accueil-moinsgros {
  font-size: $h2-font-size;
  font-weight: $boldtitreaccueil;
  color: $bleu;
  font-variant: small-caps;
  line-height: 1;


}

//nouveau titre pour la v4
.titre-h5 {
  font-size: $h5-font-size;
  line-height: 1;

}

.titre{
  line-height: 1;
}
.titre0 {
  font-size: $h5-font-size;
  line-height: 1.5;

}

.titre1 {
  font-size: $font-size-base*1.1;
  line-height: 1.5;
}

.titre1-lg {
  font-size: $font-size-base*1.3;
  line-height: 1;
}

.titre2 {
  font-size: $font-size-sm;
  line-height: 1;
}

.titre2-lg {
  font-size: $font-size-sm*1.2;
}

.titre-mention-legale {
  font-size: $font-size-sm * .9;
  line-height: 1;

}

.italic {
  font-style: italic;
}

.souligne {
  text-decoration: underline;
}

.bold {
  font-weight: bold;
}

.bold-fort {
  font-weight: 800;
}

.bold-leger {
  font-weight: 400;
}

.border-template {
  border-style: solid;
  padding: 11px;
  border-color: $rose;
}

.bg-cadre {
  background: $grisclair
}

.rose {
  color: $rose;
}

.bleu {
  color: $bleu;
}

.gris {
  color: $gray-600;
}

.smallcaps {
  font-variant: small-caps;
}

.nosmallcaps {
  font-variant: normal;
}

.bulle {
  text-decoration: underline;
  color: $rose;
  padding: 0;
  vertical-align: unset;
  line-height: 1;

}

.btn-link-bulle-light {
  color: $rose;
  text-decoration: underline;
}


.btn-link-bulle {
  font-weight: $font-weight-normal;
  color: $rose;
  text-decoration: underline;
}

.btn-link-bulle, .btn-link-bulle-light {

  padding: 0 0 1px 0;
  border: none;
  display: inline;

  &:hover {
    color: $rose;
  }

}


.calendrier a, .liensimple, .btn-link-bulle {
  text-decoration: none;
  background-image: linear-gradient($roseclair 0%, $roseclair 100%);
  background-repeat: repeat-x;
  background-position: 0 100%;
  background-size: 1px 2px;
  padding: 0 0 1px 0;
  border: none;
  display: inline;

  &:hover {
    color: inherit;
    background-size: 1px 4px;
  }
}


a:hover {
  background-size: 1px 3px;

}

#etapes1_titre {

  &:focus {
    background-size: 1px 3px;
  }


}

a:focus {

  //color: ;
  background-size: 1px 3px;
}

#step1, #step2, #step3, #step4, #etapes1_titre {
  cursor: pointer;
}


.header-img a {
  &:focus {
    background: none;

  }
}

.nolink {
  text-decoration: none;
  background: none;
}


.menu-footer {
  .list-inline > .list-inline-item:not(:last-child) {
    margin-right: 0;
  }
}

.lienbleu {
  color: $bleu;
  text-decoration: none;
  background-image: linear-gradient($bleufonce 0%, $bleu 100%);
  background-repeat: repeat-x;
  background-position: 0 100%;
  background-size: 0px 0px;
  padding: 0 0 1px 0;
  border: none;
  display: inline;

  &:hover {
    color: $bleu;
    background-size: 1px 2px;

  }

}


.telephone img {
  vertical-align: baseline;
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
  .portrait {
    display: block;
  }

}
.telephone-flex{
  display: flex;
  justify-content: center;
}


@media only screen and (max-width: 767px) {
  .text-small-device {
    display: inline;
  }
  .titre1-lg {
    font-size: $font-size-base;
  }
  .titre1 {
    font-size: $font-size-base;
    line-height: 1.5;
  }
  .titre0 {
    line-height: 1.3;
  }
  .titre-h5 {
    line-height: 1.3;
  }
  .titre-gros {
    font-size: $h4-font-size;
    color: $rose;
    font-variant: small-caps;
    line-height: 1;
  }
  .titre2 {
    line-height: 1.5;
  }
}


.logo-ministere{
  height: $spacer*3;
  width: auto;

}