//$bleu: rgba(238, 242, 249, 1);
$bleu: rgba(106, 174, 220, 1);
$bleufonce: rgba(0, 58, 121, 1);
$rose: rgba(208, 0, 123, 1);

.map {
  width: 100%
}

#france2019, .map {
  max-width: 838px;
  margin-top: 40px;
  padding-bottom: -40px;

}
#france2019 svg{
  height: 500px;
}
#france2019, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

#france2019 g path, .map {
  position: relative;
}

#france2019 g path, .island svg {
  cursor: pointer;
  transition: all .2s ease;
}

#france2019 g path, .island svg, .st0 {
  transition: all .2s ease;
}

#regionName {
  font-weight: 700;
  pointer-events: none;
}


/*
a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
*/


a:focus, button:focus, input:focus, select:focus, textarea:focus {
  outline: 0;
}


#france2019 {
  background: white;
  width: calc(100% - 20px);
  max-height: 800px;
  padding: 25px 10px;
  pointer-events: none;
}


/* test*/

.map {
  height: auto;
}

#france2019 {
  pointer-events: all;
}

#regionName {
  display: flex;
}

/** fin test */

#regionName {
  color: $rose;
  width: 100px;
  height: 100px;
  line-height: 1.2rem;
  letter-spacing: .4px;
  font-size: 1.2rem;
  position: fixed;
  top: 45px;
  left: 50px;
  z-index: 666;
  opacity: 0;
}

#regionName:before {
  content: '';
  background: $rose;
  width: 1px;
  height: 70px;
  position: absolute;
  top: 5px;
  left: -20px;
}


.cls-5 {
  fill: $bleu;
  stroke: #FFFFFF;
  stroke-width: 0.5;
}
.cls-5:hover {
  fill: $bleufonce;
}

.cls-1 {
  fill: $bleu;
  stroke: #FFFFFF;
  stroke-width: 0.5;
}

.cls-1:hover {
  fill: $bleufonce;
}

.cls-2 {
  fill: $bleu;
  stroke: #FFFFFF;
  stroke-width: 0.5;
}

.cls-2:hover {
  fill: $bleufonce;
}

.cls-3 {
  fill: $bleu;
  stroke: #FFFFFF;
  stroke-width: 0.5;
}

.cls-3:hover {
  fill: $bleufonce;
}

.cls-4 {
  fill: $bleu;
  stroke: #FFFFFF;
  stroke-width: 0.5;
}

.cls-4:hover {
  fill: $bleufonce;
}

.st0 {
  fill: #ff7c4e;

}

#Guyane {
  width: 70px;
  height: auto;
  position: relative;
  top: -7px;
}

/*

@media (orientation: portrait) {
  .portrait {
    display: block;
  }

  #regionName {
    color: greenyellow;

  }


}
*/
